/**
 * WORKMATRIX GMBH
 */

// fire when the content is here!
document.addEventListener('DOMContentLoaded', function () {

});

let headerHeight = document.querySelector('body > header') ? document.querySelector('body > header').offsetHeight : 0

// Passive scroll listener
document.addEventListener('scroll', function () {
	if (window.scrollY > headerHeight) {
		document.body.classList.add('scrolled')
	} else {
		document.body.classList.remove('scrolled')
	}
}, {passive: true});

/**
 * Debounce helper
 */
function debounce(func) {
	var timer;
	return function (event) {
		if (timer) clearTimeout(timer);
		timer = setTimeout(func, 100, event);
	};
}

/**
 * Window resize helper
 */
const onResizeWindow = function (func) {
	window.addEventListener('resize', debounce(func));
}
const triggerWindowResize = function () {
	const el = document; // This can be your element on which to trigger the event
	const event = document.createEvent('HTMLEvents');
	event.initEvent('resize', true, false);
	el.dispatchEvent(event);
}

/**
 * helper function to hide elements
 */
Node.prototype.hide = function () {
	this.style.display = 'none'
	return this
}
Node.prototype.show = function () {
	this.style.display = ''
	return this
}
Node.prototype.hideElements = function (selector) {
	const elems = this.querySelectorAll(selector)
	for (let i = 0; i < elems.length; i++) {
		elems[i].hide()
	}
}
Node.prototype.showElements = function (selector) {
	const elems = this.querySelectorAll(selector)
	for (let i = 0; i < elems.length; i++) {
		elems[i].show()
	}
}



/*
 MODULE 015: Contact
 */
$('.m015_contacts').each(function () {
	var container = $(this);
	var count = container.find('.m015_contacts_box').length;

	if (count % 2 == 1) {
		container.find('.m015_contacts_box').last().addClass('nospace');
	} else {
		container.find('.m015_contacts_box').last().addClass('nospace');
		container.find('.m015_contacts_box').last().prev().addClass('nospace');
	}
});

/*
 MODULE 010: News
 */
$('.news_container').each(function () {
	var container = $(this);
	var count = container.find('.news').length;


	if (count % 3 == 1) {
	} else if (count % 3 == 2) {
		container.find('.news').last().prev().addClass('nospace');
	} else {
		container.find('.news').last().prev().addClass('nospace');
		container.find('.news').last().prev().prev().addClass('nospace');
	}
});

/*
 MODULE 090: Carousel
 */
const $carousels = $('.carousel');
if ($carousels.length) {
	$carousels.each(function () {
		const $carousel = $(this);
		$carousel.on('setPosition', function (event, slick) {
			var container = $(this).parent().find('#carousel_controls');
			$(this).find('.slick-prev').appendTo(container);
			$(this).find('.slick-dots').appendTo(container);
			$(this).find('.slick-next').appendTo(container);

			setSlickDots();
		});

		$carousel.on('init', function (event, slick) {
			var container = $(this).parent().find('#carousel_controls');
			$(this).find('.slick-prev').appendTo(container);
			$(this).find('.slick-dots').appendTo(container);
			$(this).find('.slick-next').appendTo(container);

			setSlickDots();
		});

		$carousel.slick({
			speed: 1100,
			mobileFirst: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					}
				},
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4,
					}
				}
			]
		});
	});
}

function setSlickDots() {
	$('.carousel').each(function () {
		var count = $(this).parent().find('.slick-dots').children().length;
		if (count <= 1) {
			$(this).parent().find('.slick-dots').hide();
		}
	});
}

/*
 NAVIGATION
 */
const navigation = {
	id: 'navi',
	naviElement: null,
	bodyOverlayClass: 'open-overlay',
	showPanelClass: 'panel--active',
	isOpen: false,
	lastOpenPanel: null,
	naviBreakPoint: 900,

	/**
	 * toggle the offscreen navigation on hamburger click
	 * @param e event
	 */
	toggle: function (e, viaKeyboard) {
		e.preventDefault()
		e.stopPropagation()
		if (navigation.isOpen) return navigation.close(e)
		return navigation.open(e, viaKeyboard)
	},

	/**
	 * toggle navi on pressing enter key
	 * @param e event
	 */
	toggelOnEnter: function (e) {
		e.preventDefault()
		if (e.keyCode == 13) {
			return navigation.toggle(e, true)
		}
		return false
	},

	/**
	 * open the offscreen navigation
	 * @param e event
	 */
	open: function (e, viaKeyboard) {
		this.naviElement.show()
		this.naviElement.showElements('.panel')
		setTimeout(function(){
			navigation.resetPanels()
			document.body.classList.add(navigation.bodyOverlayClass)
			navigation.isOpen = true
			if (viaKeyboard) navigation.naviElement.querySelector('a').focus()
			navigation.lastOpenPanel = !!navigation.lastOpenPanel ? navigation.lastOpenPanel : navi
			navigation.openPanel(e)
		}, 4)
	},

	/**
	 * close the offscreen navigation
	 * @param e event
	 * @param force force closure of navi (no matter where clicked)
	 */
	close: function (e, force) {
		if (!!force || e.target === document.body) {
			document.body.classList.remove(navigation.bodyOverlayClass)
			navigation.isOpen = false
			navigation.closeAllPanels()
		}
	},

	/**
	 * open a subnav panel when on mobile
	 * @param e
	 */
	openPanel: function (e) {
		e.preventDefault()
		e.stopPropagation()
		const panel = document.querySelector(e.target.getAttribute('href'))
		if (panel) {
			// close all active panels
			const activePanels = navigation.naviElement.querySelectorAll('.' + navigation.showPanelClass)
			for (let i = 0; i < activePanels.length; i++) {
				if (activePanels[i] === panel) continue
				activePanels[i].classList.remove(navigation.showPanelClass)
				setTimeout(function(){
					activePanels[i].hide()
				}, 333) // after css transition
			}
			panel.show()
			setTimeout(function () {
				panel.classList.add(navigation.showPanelClass)
				if (e.keyCode && e.keyCode == 13) {
					panel.querySelector('a').focus()
				}
			}, 4)
		}
	},

	// here the target is the panel that is over the currently active panel
	closePanel: function (e) {
		//
	},

	/**
	 * close all active panels when on mobile navigation
	 */
	closeAllPanels: function () {
		const activePanels = document.querySelectorAll('.' + this.showPanelClass)
		for (let i = 0; i < activePanels.length; i++) {
			activePanels[i].classList.remove(this.showPanelClass)
		}

		setTimeout(function() {
			if (navigation.open && window.innerWidth < navigation.naviBreakPoint) {
				navigation.naviElement.hide()
			}
		}, 333) // after css transition
	},

	/**
	 * reset alignment styles of subnav panels
	 */
	resetPanels: function () {
		const panels = this.naviElement.querySelectorAll('.panel')
		for (let i = 0; i < panels.length; i++) {
			//panels[i].removeAttribute('style')
			panels[i].style.left = null
		}
	},

	/**
	 * when opening a suibnav panel check the position if it fits on the right
	 * @param e event
	 */
	subnaviUpdatePosition: function (e) {
		navigation.unHoverMainitems()
		const panel = e.target.parentNode.querySelector('.panel')
		if (panel) {
			panel.style.left = null
			panel.style.opacity = '0' // set opacity to remove flickering
		}
		if (window.innerWidth < navigation.naviBreakPoint) {
			panel.style.opacity = null
			return true
		}
		setTimeout(function() {
			if (panel) {
				const baseLeft = parseInt(getComputedStyle(panel)['left'])
				if (panel.offsetWidth + panel.getBoundingClientRect().x > navigation.naviElement.parentNode.offsetWidth) {
					panel.style.left = (navigation.naviElement.parentNode.offsetWidth - (panel.offsetWidth + panel.getBoundingClientRect().x)) + 'px';
				} else {
					panel.style.left = baseLeft + 'px'
				}
				panel.style.opacity = null // remove opacity
			}
		}, 22)
	},

	/**
	 * remove JS added hover class when touching items
	 */
	unHoverMainitems: function () {
		const hoveredItems = this.naviElement.querySelectorAll('li.hover')
		for (let i = 0; i < hoveredItems.length; i++) {
			hoveredItems[i].classList.remove('hover')
		}
	},

	/**
	 * initialize the navigation
	 * subnav panels and offscreen for mobile
	 * bind resize and touch/click/keyboard events
	 */
	initialize: function () {

		const hamburger = document.querySelectorAll('a[href="#' + this.id + '"]')
		this.naviElement = document.querySelector('#' + this.id)

		if (this.naviElement) {
			if (hamburger.length) {
				for (let i = 0; i < hamburger.length; i++) {
					hamburger[i].addEventListener('click', this.toggle);
					hamburger[i].addEventListener('keydown', this.toggelOnEnter);
				}
			}
			const header = document.querySelector('body > header')
			header.addEventListener('click', function(e) {
				if (e.target == header) navigation.close(e, true)
			})

			const localLinks = this.naviElement.querySelectorAll('a[href^="#"]:not([href="#"])')
			for (let i = 0; i < localLinks.length; i++) {
				localLinks[i].addEventListener('click', navigation.openPanel)
				localLinks[i].addEventListener('keyup', function(e) {
					// TODO: TAB navigation vs. submenus
					if (e.keyCode == 13) return navigation.openPanel(e)
				})
			}

			const mainNaviItems = this.naviElement ? this.naviElement.querySelectorAll('.scroller > ul > li > a') : []
			for (let i = 0; i < mainNaviItems.length; i++) {
				mainNaviItems[i].addEventListener('mouseenter', navigation.subnaviUpdatePosition)
				mainNaviItems[i].addEventListener('focusin', navigation.subnaviUpdatePosition)
				// mainNaviItems[i].addEventListener('keyup', function(e){
				// 	e.preventDefault()
				// })
				mainNaviItems[i].addEventListener('touchstart', function (e) {
					if (window.innerWidth < navigation.naviBreakPoint) return true
					if (!e.target.parentNode.classList.contains('hover')) {
						navigation.unHoverMainitems()
						e.target.parentNode.classList.add('hover')
						e.preventDefault()
					}
				}, {passive: true})
			}

			onResizeWindow(function(){
				// close panels if opened
				navigation.close(null, true)

				// hide mobile navi
				if (navigation.open && window.innerWidth < navigation.naviBreakPoint) {
					navigation.naviElement.hide()
					navigation.naviElement.hideElements('.panel')
				} else {
					navigation.naviElement.show()
					navigation.naviElement.showElements('.panel')
				}
			})

			document.body.addEventListener('keyup', function (e) {
				// ESC pressed on navi open
				if (navigation.open && e.keyCode == 27) {
					navigation.close(null, true)
					e.preventDefault()
				}
			})
		}


	}
}

navigation.initialize();

const searchpanel = {
	panel: document.querySelector('#searchPanel'),

	isOpen: false,

	open: function () {
		searchpanel.panel.setAttribute('aria-expanded', true)
		// focus input
		searchpanel.panel.querySelector('input[type="text"]').focus()
		searchpanel.isOpen = true
	},
	close: function () {
		searchpanel.panel.setAttribute('aria-expanded', false)
		searchpanel.isOpen = false
	},
	toggle: function (e) {
		e.preventDefault()
		if (searchpanel.isOpen) return searchpanel.close()
		return searchpanel.open()
	},

	initialize: function () {
		if (!this.panel) return

		// close on overlay click
		this.panel.addEventListener('click', function(e){
			if (e.target === searchpanel.panel) searchpanel.close()
		})

		const searchToggles = document.querySelectorAll('a[href="#searchPanel"]');
		for (let i = 0; i < searchToggles.length; i++) {
			const toggle = searchToggles[i];
			toggle.addEventListener('click', searchpanel.toggle)
		}
		this.panel.querySelector('input[type="text"]').addEventListener('keyup', function (e) {
			if (searchpanel.isOpen && e.keyCode == 27) {
				searchpanel.close()
			}
		})
	}
}

searchpanel.initialize();

const toasts = {

	hiddenClass: 'toast-item--hidden',
	closingClass: 'toast-item--closing',

	toastLinks: document.querySelectorAll('a[data-target="toast"]'),

	toastContainer: document.getElementById('toast-container'),

	getToastContainer: function () {
		if (!toasts.toastContainer) {
			// generate toast container
			const ct = document.createElement('div')
			ct.classList.add('toast-container')
			ct.id = 'toast-container'
			document.body.append(ct)
			return ct
		}
		return toasts.toastContainer
	},

	addToastToContainer: function (toast) {
		// create toast
		const toastElement = document.createElement('div')
		toastElement.classList.add('toast-item', toasts.hiddenClass)

		toastElement.innerHTML = '<a href="#" class="closer"></a>'

		if (toast.title) {
			toastElement.innerHTML = `${toastElement.innerHTML}<header>${toast.title}</header>`
		}

		if (toast.content) {
			toastElement.innerHTML = `${toastElement.innerHTML}<p>${toast.content}</p>`
		}

		if (toast.bg) {
			toastElement.classList.add('bg-' + toast.bg)
		}
		this.toastContainer.append(toastElement)
		setTimeout(function () {
			toastElement.classList.remove(toasts.hiddenClass)
			toastElement.querySelector('a.closer').addEventListener('click', function (e) {
				e.preventDefault()
				toasts.removeToastelement(toastElement)
			})
		}, 32)
	},

	removeToastelement: function (el) {
		el.classList.add(toasts.closingClass)
		setTimeout(function () {
			el.remove()
		}, 342)
	},

	initialize: function () {

		this.toastContainer = this.getToastContainer()

		for (let i = 0; i < this.toastLinks.length; i++) {
			const link = this.toastLinks[i]

			link.addEventListener('click', function (e) {
				e.preventDefault()
				const toast = {
					bg: e.target.getAttribute('data-toastbg') || null, // optional
					title: e.target.getAttribute('data-toasttitle') || null, // optional
					content: e.target.getAttribute('data-toastcontent') || null
				}
				toasts.addToastToContainer(toast)
			})
		}

		const toastItems = this.toastContainer.querySelectorAll('.toast-item')
		for (let i = 0; i < toastItems.length; i++) {
			const item = toastItems[i]
			setTimeout(function () {
				item.classList.remove(toasts.hiddenClass)
			}, 332)
			item.querySelector('a.closer').addEventListener('click', function (e) {
				e.preventDefault()
				toasts.removeToastelement(item)
			})
		}
	}
}
toasts.initialize()

const footer = {
	initialize: function () {
		const siteFooter = document.querySelector('body > footer > .footer')
		if (!siteFooter) return

		const headlines = siteFooter.querySelectorAll('.footer-top header')
		for (let i = 0; i < headlines.length; i++) {
			headlines[i].addEventListener('click', function (e) {
				e.preventDefault()
				e.stopPropagation()
				e.target.classList.toggle('active')
			})
		}

	}
}
footer.initialize()

//triggerWindowResize();

/* FORMULAR LABEL */
const $movelabelContainers = $('.moveLabel_container');
$movelabelContainers.each(function(){
	const  $container = $(this);
	const $movelabelInputs = $('input', $container);


	$movelabelInputs.each(function () {
		var $this = $(this);
		if ($this.val() !== '') {
			$container.addClass('filled');
		} else {
			$container.removeClass('filled');
		}

		$this.on('blur', function () {
			if ($this.val() !== '') {
				$container.addClass('filled');
			} else {
				$container.removeClass('filled');
			}
		});

		$this.on('focus', function () {
			$container.addClass('filled');
		});
	});
});


/* SELECT 2*/

const $selects = $('.js-select');

// Workaround to trigger native change event
// @see https://github.com/select2/select2/issues/1908#issuecomment-871095578
function addNativeEventTrigger(e) {
	if (window.document.documentMode) {
		// (IE doesnt support Event() constructor)
		// https://caniuse.com/?search=Event()
		var evt = document.createEvent('HTMLEvents');
		evt.initEvent('change', false, true);
		e.currentTarget.dispatchEvent(evt);
	} else {
		const event = new Event('change');
		e.currentTarget.dispatchEvent(event);
	}
	$(e.currentTarget).one('change', this.addNativeEventTrigger);
}

window.handleSelect = function (elem) {
	const $this = $(elem);
	var $container = $this.parent();

	if ($this.val() !== '') {
		$container.addClass('filled');
	} else {
		$container.removeClass('filled');
	}


	let placeholder = '';
	const nonOption = $this.find('option[value=""], option:not([value])');

	if (nonOption.length) {
		placeholder = nonOption.first().text();
	}


	if($this.attr('id') == 'department'){
		var search = 1;
	} else{
		var search = -1;
	}

	$this.select2({
		minimumResultsForSearch: search,
		placeholder: placeholder,
		templateSelection: function (data) {
			if (data.id === '') { // adjust for custom placeholder values
				return 'Custom styled placeholder text';
			}

			return data.text;
		}
	});

	$this.one('change', addNativeEventTrigger);

	$this.on('select2:select', function (e) {
		$(this).trigger('change')
	});
	$this.on('select2:open', function (e) {

		var $this = $(this);
		var $container = $this.parent();
		$container.addClass('filled');
	});

	$this.on('select2:closing', function (e) {

		var $this = $(this);
		var $container = $this.parent();
		if ($this.val() !== '') {
			$container.addClass('filled');
		} else {
			$container.removeClass('filled');
		}
	});
}

window.handleMultiSelect = function (elem, options) {
	const $this = $(elem);
	const opt = options || {};

	const triggerChange = function(el){
		if (window.document.documentMode) {
			// (IE doesnt support Event() constructor)
			// https://caniuse.com/?search=Event()
			var evt = document.createEvent('HTMLEvents');
			evt.initEvent('change', false, true);
			el.dispatchEvent(evt);
		} else {
			const event = new Event('change');
			el.dispatchEvent(event);
		}
	}

	opt.onLoad = function(el){
		triggerChange(el);
	};
	opt.onOptionClick = function (el) {
		triggerChange(el);
	};
	opt.onPlaceholder = function (element, placeholder, selectedOpts) {
		return 'placeholder'
	};
	$this.multiselect(opt);
}

$selects.each(function () {
	var $this = $(this);

	handleSelect($this);
});

/*MAPBOX SIMPLE MODULE 019 PLANNING*/
if (typeof mapboxgl != 'undefined') mapboxgl.accessToken = 'pk.eyJ1Ijoic3RlaWdlbmJlcmdlciIsImEiOiJjam04c2xiZGUwMXRjM3FtbzA1eWozeXR5In0.MUm267Uc0qttcP76YmrXXg';

$('.map').each(function () {
	var that = $(this);
	var id = that.attr('id');
	var lat = parseFloat(that.attr('data-lat'));
	var lng = parseFloat(that.attr('data-lng'));

	if(isNaN(lng)){
		// do nothing
	} else{

		var geojson = {
			'type': 'FeatureCollection',
			'features': [
				{
					'type': 'Feature',
					'geometry': {
						'type': 'Point',
						'coordinates': [lng, lat]
					}
				}
			]
		};


		var map = new mapboxgl.Map({
			container: id, // container ID
			style: 'mapbox://styles/steigenberger/cjskc20ib467u1fs4u0vl3xef', // style URL
			center: [lng, lat], // starting position [lng, lat]
			zoom: 8 // starting zoom
		});

		// Add markers to the map.
		geojson.features.forEach(function (marker) {
			// Create a DOM element for each marker.
			var el = document.createElement('div');
			el.className = 'marker';


			// Add markers to the map.
			new mapboxgl.Marker(el)
				.setLngLat(marker.geometry.coordinates)
				.addTo(map);
		});

		// disable map zoom when using scroll
		map.scrollZoom.disable();

	}
});

$(function () {

	$('.planning_slider').each(function () {

		var that = $(this);
		var link = that.parent().find('.prev_arrow');

		that.on('init', function (event, slick, direction) {
			const dots = $(this).find('.slick-dots');

			var slide_count = dots.find('li').length;

			if(slide_count > 1){
				dots.insertAfter(link);
				const $nav = that.next('.slider_nav_container');
				if ($nav.length) {

					$('.prev_arrow', $nav).on('click', function () {
						that.slick('slickPrev');
					});
					$('.next_arrow', $nav).on('click', function () {
						that.slick('slickNext');
					});
				}
			} else{
				that.next('.slider_nav_container').hide();
				$(this).find('.slick-dots').hide();
				that.css('margin-bottom','0')
			}

		});

		that.slick({
			dots: true,
			arrows: true,
			prevArrow: $('.prev-arrow'),
			nextArrow: $('.next-arrow'),
			speed: 650
		});

	});
});

if ($('#pickadate_from').length) {

	$('.m14_bookingpanel_form').each( function() {

		var form = $(this);
		var arrival = form.find('#pickadate_from');
		var departure = form.find('#pickadate_to');

		var language = $('.js-bookingpanel').attr('data-booking-lang');

		if (language == 'de') {
			$.extend($.fn.pickadate.defaults, {
				monthsFull: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
				monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
				weekdaysFull: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
				weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			});
		}

		var $dateFrom      = arrival.pickadate({
			min: 0,
			firstDay: 1,
			format: 'dd.mm.yyyy',
			formatSubmit: 'yyyy-mm-dd',
		}), dateFromPicker = $dateFrom.pickadate('picker');

		if (departure.length) {
			var $dateTo      = departure.pickadate({
				min: 1,
				firstDay: 1,
				format: 'dd.mm.yyyy',
				formatSubmit: 'yyyy-mm-dd',
			}), dateToPicker = $dateTo.pickadate('picker');

			dateFromPicker.on({
				open: function () {
					setTimeout(function () {
						dateFromPicker.open();
					}, 50);

					dateToPicker.close();
				},
				close: function () {
					// set mindate of departure after changing arrival
					var select = dateFromPicker.get('select');
					var minDate = (select.pick || 0) + 1000 * 3600 * 24;
					dateToPicker.set('min', new Date(minDate));
					if (dateToPicker.get() <= dateFromPicker.get()) {
						dateToPicker.set('clear');
					}

					if ($dateFrom.val()) {
						dateToPicker.open();
					}

					// workaround for github issue #160
					$(document.activeElement).blur();
				}
			});

			dateToPicker.on({
				open: function(){
					dateFromPicker.close();
				},
				close: function (e) {
					// workaround for github issue #160
					$(document.activeElement).blur();
				}
			});
		}
	});
}

/* MODULE 014: Bookingpanel popup */
$('.js-open-bookingpanel').on('click', function (e) {
	e.preventDefault();
	document.documentElement.style.overflow = 'hidden';
	$('.m14_bookingpanel.popup').addClass('show');
});

$('.close_bookingpanel,.m14_bookingpanel').on('click', function (e) {
	e.preventDefault();
	document.documentElement.style.overflow = null;
	$('.m14_bookingpanel.popup').removeClass('show');
});

$('.m14_bookingpanel_inner').on('click', function (e) {
	e.stopPropagation();
});


/* MODUL 17: count up*/
$(function () {
	$.fn.countTo = function (options) {
		options = options || {};

		return $(this).each(function () {
			// set options for current element
			var settings = $.extend({}, $.fn.countTo.defaults, {
				from: $(this).data('from'),
				to: $(this).data('to'),
				speed: $(this).data('speed'),
				refreshInterval: $(this).data('refresh-interval'),
				decimals: $(this).data('decimals')
			}, options);

			// how many times to update the value, and how much to increment the value on each update
			var loops     = Math.ceil(settings.speed / settings.refreshInterval),
				increment = (settings.to - settings.from) / loops;

			// references & variables that will change with each update
			var self      = this,
				$self     = $(this),
				loopCount = 0,
				value     = settings.from,
				data      = $self.data('countTo') || {};

			$self.data('countTo', data);

			// if an existing interval can be found, clear it first
			if (data.interval) {
				clearInterval(data.interval);
			}
			data.interval = setInterval(updateTimer, settings.refreshInterval);

			// initialize the element with the starting value
			render(value);

			function updateTimer() {
				value += increment;
				loopCount++;

				render(value);

				if (typeof (settings.onUpdate) == 'function') {
					settings.onUpdate.call(self, value);
				}

				if (loopCount >= loops) {
					// remove the interval
					$self.removeData('countTo');
					clearInterval(data.interval);
					value = settings.to;

					if (typeof (settings.onComplete) == 'function') {
						settings.onComplete.call(self, value);
					}
				}
			}

			function render(value) {
				var formattedValue = settings.formatter.call(self, value, settings);
				$self.html(formattedValue);
			}
		});
	};

	$.fn.countTo.defaults = {
		from: 0,               // the number the element should start at
		to: 0,                 // the number the element should end at
		speed: 200,           // how long it should take to count between the target numbers
		refreshInterval: 8,  // how often the element should be updated
		decimals: 0,           // the number of decimal places to show
		formatter: formatter,  // handler for formatting the value before rendering
		onUpdate: null,        // callback method for every time the element is updated
		onComplete: null       // callback method for when the element finishes updating
	};

	const locale = document.documentElement.getAttribute('lang') || 'de-DE';

	function formatter(value, options) {
		return value.toLocaleString(locale, {
			minimumFractionDigits: options.decimals,
			maximumFractionDigits: options.decimals
		});
		//return value.toFixed(options.decimals);
	}

	inView.offset({
		bottom: 400,
	});

	inView('.m17_development').once('enter', function (event, isInView) {
		// // start all the timers
		$('.js-counter').each(count);
	});

	function count() {
		var $this = $(this);

		var min = 1;
		var max = 6;
		// and the formula is:
		var random = Math.floor(Math.random() * (max - min + 1)) + min;

		setTimeout(function () {
			const options = $.extend({}, $this.attr('data-count') || {});
			$this.countTo(options);
		}, 84 * random);
	}
});


const m27_modal = {

	xhr: new XMLHttpRequest(),

	// selector for generic modal links
	baseModalLinkSelector: '[data-target="modal"]',

	// class to show the active modals
	showModalBodyClass: 'show-modal',

	// generic modal if needed
	genericModal: null,

	videoPlayer: null,

	/**
	 * click handler that opens the generic modal
	 * @param e event
	 */
	clickOpenGenericModal: function (e) {
		e.preventDefault();

		// video link or not?
		if (e.target.hasAttribute('data-video')) {

			// extract video ID
			const videoId = m27_modal._extractVideoInfoFromUrl(e.target.href);
			const videoProvider = e.target.getAttribute('data-video');

			// <div id="player" data-plyr-provider="youtube" data-plyr-embed-id="bTqVqk7FSmY"></div>
			const playerTag = document.createElement('div');
			const playerId = 'player-' + videoId;
			playerTag.id = playerId;
			playerTag.setAttribute('data-plyr-embed-id', videoId);
			playerTag.setAttribute('data-plyr-provider', videoProvider);

			const inner = m27_modal.genericModal.querySelector('.inner');
			inner.innerHTML = '';
			inner.appendChild(m27_modal._generateCloser());
			inner.appendChild(playerTag);

			m27_modal.genericModal.setAttribute('data-fullscreen', '');

			// init player
			const plyrOptions = {
				autoplay: true,
				loop: { active: false },
				// muted: true,
			};

			const launchPlyr = function(){
				m27_modal.openModal(m27_modal.genericModal);
				m27_modal.videoPlayer = new Plyr('#'+playerId, plyrOptions);
			}

			if (typeof Plyr == 'undefined') {

				let cssLoaded = false;
				const plyrCSSFile = window.environment = 'development' ? '/node_modules/plyr/dist/plyr.css' : '/styles/video.css';
				let jsLoaded = false;
				const plyrJSFile = window.environment = 'development' ? '/node_modules/plyr/dist/plyr.min.js' : '/scripts/video.js';

				const style = document.createElement('link');
				style.onload = function () {
					cssLoaded = true;
					if(jsLoaded) launchPlyr();
				};
				style.href = plyrCSSFile;
				style.rel = 'stylesheet';
				style.type = 'text/css';
				document.head.appendChild(style);

				const script = document.createElement('script');
				script.onload = function () {
					jsLoaded = true;
					if(cssLoaded) launchPlyr();
				};
				script.src = plyrJSFile;
				document.head.appendChild(script);
			}
			else {
				launchPlyr();
			}

		} else {

			let ajaxError = false;
			const target = e.target.getAttribute('href') || false;
			const request = m27_modal.xhr;

			if (!target) {
				ajaxError = true;
			} else {
				request.open('GET', target, true);

				request.onload = function () {
					if (this.status >= 200 && this.status < 400) {
						// Success!
						const inner = m27_modal.genericModal.querySelector('.inner');
						inner.innerHTML = this.response;
						inner.insertBefore(m27_modal._generateCloser(), inner.firstChild);

						m27_modal.openModal(m27_modal.genericModal);

					} else {
						// We reached our target server, but it returned an error
						ajaxError = true;
					}
				};

				request.onerror = function () {
					// There was a connection error of some sort
					ajaxError = true;
					console.log(ajaxError);
				};

				request.send();
			}
		}
	},

	_extractVideoInfoFromUrl: function (url) {
		const video_id_regExp = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		const match           = url.match(video_id_regExp);

		if (match && match[7]) {
			//valid
			return match[7];

		} else {
			return false;
		}
	},

	/**
	 * click handler for special modal links
	 * @param e
	 */
	clickOpenSpecialModal: function (e) {
		e.preventDefault();
		const targetSelector = e.target.hasAttribute('data-target') ? e.target.getAttribute('data-target') : e.target.getAttribute('href');
		const modal = document.querySelector(targetSelector);
		if (modal) m27_modal.openModal(modal);
	},

	/**
	 * opens a modal
	 * @param modal
	 */
	openModal: function (modal) {
		modal.setAttribute('aria-hidden', false);
		document.body.classList.add(m27_modal.showModalBodyClass);
		document.documentElement.style.overflow = 'hidden';
		window.addEventListener('keyup', m27_modal.clickCloseModal);
	},

	/**
	 * click event that closes the modals
	 */
	clickCloseModal: function (e) {

		if(typeof e.keyCode != 'undefined' && e.keyCode != 27) return;

		e.preventDefault();
		document.body.classList.remove(m27_modal.showModalBodyClass);
		document.documentElement.style.overflow = null;
		const openModals = document.querySelectorAll('body > .modal[aria-hidden="false"]');
		for (let i = 0; i < openModals.length; i++) {
			openModals[i].setAttribute('aria-hidden', true);
		}
		m27_modal.genericModal.removeAttribute('data-fullscreen');

		if(typeof m27_modal.videoPlayer != 'undefined') {
			try {
				m27_modal.videoPlayer.destroy()
			}
			catch (e) {
				/* silence */
			}
		}

		window.removeEventListener('keyup', m27_modal.clickCloseModal);
	},

	/**
	 * builds a generic modal wrapper and appends it to body
	 */
	_buildGenericModal: function () {
		if (!this.genericModal) {
			const inner = document.createElement('div');
			const modal = document.createElement('div');

			inner.classList.add('inner');

			modal.classList.add('m27_modal', 'modal');
			modal.appendChild(inner);
			document.body.appendChild(modal);

			// click to close
			modal.addEventListener('click', function (e) {
				if (modal === e.target) {
					m27_modal.clickCloseModal(e);
				}
				// close fullscreen modal on click on '.inner'
				if (modal.hasAttribute('data-fullscreen') && e.target.classList.contains('inner')) {
					m27_modal.clickCloseModal(e);
				}
			});

			this.genericModal = modal;
		}

		return this.genericModal;
	},

	/**
	 * generate a closer link
	 * @private
	 */
	_generateCloser: function () {
		const closer = document.createElement('a');

		closer.classList.add('closer');
		closer.setAttribute('href', '#');
		closer.addEventListener('click', m27_modal.clickCloseModal);
		closer.innerHTML = '<span class="sr-only">close</span>';

		return closer;
	},

	init: function () {

		const modals = document.querySelectorAll('body > .modal');

		const genericModalLinks = document.querySelectorAll(this.baseModalLinkSelector);
		if (genericModalLinks.length) this._buildGenericModal();
		for (let i = 0; i < genericModalLinks.length; i++) {
			const modalLink = genericModalLinks[i];
			modalLink.addEventListener('click', this.clickOpenGenericModal);
		}

		// special modal links
		for (let i = 0; i < modals.length; i++) {
			const mod = modals[i];
			const modLinks = document.querySelectorAll('a[href="#' + mod.id + '"], a[data-target="#' + mod.id + '"]');
			for (let i = 0; i < modLinks.length; i++) {
				modLinks[i].addEventListener('click', this.clickOpenSpecialModal);
			}
			// closer ?
			const closers = mod.querySelectorAll('a.closer');
			for (let j = 0; j < closers.length; j++) {
				closers[i].addEventListener('click', this.clickCloseModal);
			}
			// add closer if there is none
			if (!closers.length) {
				const inner = mod.querySelector('.inner');
				if (inner) inner.appendChild(this._generateCloser());
				else mod.appendChild(this._generateCloser());
			}
			// close on modal or bg click
			// click to close
			mod.addEventListener('click', function (e) {
				if (mod === e.target) {
					m27_modal.clickCloseModal(e);
				}
			});
		}
	}
}

m27_modal.init();

// stage slider
const stageSlider = {
	// slector for stage slider wrappers
	stageSelector: '.stage__slider',

	loadImages: function (slides) {
		for (let i = 0; i < slides.length; i++) {
			const slide = slides[i];
			const lazyImages = slide.querySelectorAll('img[data-src], img[sizes="auto"]');
			for (let j = 0; j < lazyImages.length; j++) {
				const img = lazyImages[j];
				img.setAttribute('src', img.getAttribute('data-src'));
				img.classList.add('loaded');
				img.removeAttribute('data-src');
			}
			slide.classList.add('loaded');
		}
	},

	initBGVideo: function () {

		const controls = [
			//'play-large', // The large play button in the center
			//'restart', // Restart playback
			//'rewind', // Rewind by the seek time (default 10 seconds)
			//'play', // Play/pause playback
			//'fast-forward', // Fast forward by the seek time (default 10 seconds)
			//'progress', // The progress bar and scrubber for playback and buffering
			//'current-time', // The current time of playback
			//'duration', // The full duration of the media
			//'mute', // Toggle mute
			//'volume', // Volume control
			//'captions', // Toggle captions
			//'settings', // Settings menu
			//'pip', // Picture-in-picture (currently Safari only)
			//'airplay', // Airplay (currently Safari only)
			//'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
			//'fullscreen', // Toggle fullscreen
		];
		try {
			const player = Plyr.setup('.js-bg-player', {storage: { enabled: true, key: 'plyr-bg' }, controls: controls});
		} catch (e) {
			// nothing to catch
		}
	},

	init: function () {
		const stages = document.querySelectorAll(this.stageSelector);
		for (let i = 0; i < stages.length; i++) {
			const stage = stages[i];
			const slides = stage.querySelectorAll('.stage__slide');
			this.loadImages(slides);
			if (slides.length < 2) continue;

			const autoplay = stage.getAttribute('data-autoplay') || false;

			$(stage).slick({
				dots: true,
				arrows: false,
				fade: true,
				autoplay: autoplay,
			});
		}
		document.addEventListener('DOMContentLoaded', function() {
			stageSlider.initBGVideo();
		});
	},
};
stageSlider.init();


// stage slider
const bookingPanel = {

	init:  function () {
		$('#department').change(function () {
				var where = $(this).parent().parent().find('#where');
				var where_s = $(this).parent().parent().find('#where_s');

				var page_id = $(this).val();
				var sturcture_id = $(this).find(':selected').attr('data-structure-id');

				where.val(page_id);
				where_s.val(sturcture_id);
			});
		}
};


let init_booking_panel = $('#booking_panel');

if(init_booking_panel){
	bookingPanel.init();
}


/* show nav when scroll upwards*/
let position = $(window).scrollTop();

// should start at 0
$(window).on('scroll', function() {
    const scroll = $(window).scrollTop();
	const header = document.querySelector('body > header');

	// scroll up again
    if(scroll > position) {
		header.classList.remove('visible');
    } else {
		header.classList.add('visible');
		header.classList.remove('transparent');
    }
	position = scroll;

	if(scroll > 112){
		header.classList.add('hiddenNav');
	} else {
		header.classList.remove('hiddenNav');
		if(header.hasAttribute('data-transparent')){
			header.classList.add('transparent');
		}
	}
});


/* handle download language */

$(document.body).on('change','.js-download-select',function(){

	var select = $(this);
	var container = select.parent().parent().parent().parent();
	var value = select.val();
	var download = $('.js-download-'+value);


	container.find('.download').addClass('hidden');
	container.find(download).removeClass('hidden');

});

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});

